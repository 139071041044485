<template>
<div class="kt-section pt-4">
    <div class="kt-section__content kt-section__content--border kt-section__content--fit">
        <ul class="kt-nav kt-nav--md-space kt-nav--lg-font">
            <li class="kt-nav__head mb-3">
                {{ title }}
                <SVGIcon
                    :name="icon"
                    class="kt-nav__link-icon kt-svg-icon"
                />
            </li>
            <li
                v-for="(item, idx) in items"
                :key="`route_${idx}_${item.routeName}`"
                class="kt-nav__item"
            >
                <router-link
                    :to="{name: item.routeName }"
                    class="kt-nav__link"
                >
                    <span class="kt-nav__link-bullet kt-nav__link-bullet--dot">
                        <span />
                    </span>
                    <span class="kt-nav__link-text">
                        {{ item.title }}
                    </span>
                    <span class="kt-nav__link-badge d-none">
                        <span class="kt-badge kt-badge--warning">3</span>
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import SVGIcon from './SVGIcon/SVGIcon.vue';

export default Vue.extend({
    name: 'NavigationMenu',
    components: { SVGIcon },
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
});

</script>
