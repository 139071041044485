var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _vm._m(0),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "row" }, [
              _vm.$_userMixins_isSchoolAdmin
                ? _c(
                    "div",
                    { staticClass: "col-xl-6" },
                    [
                      _c("NavigationMenu", {
                        attrs: {
                          title: "School Administration",
                          icon: "school",
                          items: _vm.adminLinks,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$_userMixins_isSchoolAdmin
                ? _c(
                    "div",
                    { staticClass: "col-xl-6" },
                    [
                      _c("NavigationMenu", {
                        attrs: {
                          title: "File Management",
                          icon: "download",
                          items: _vm.fileLinks,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$_userMixins_isSchoolAdmin
                ? _c(
                    "div",
                    { staticClass: "col-xl-6" },
                    [
                      _c("NavigationMenu", {
                        attrs: {
                          title: "Report Cards",
                          icon: "reportCards",
                          items: _vm.reportCardLinks,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "col-xl-6" },
                [
                  _c("NavigationMenu", {
                    attrs: {
                      title: "Google Classroom",
                      icon: "googleClassroom",
                      items: _vm.googleLinks,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" SyncGrades Settings "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }