<template>
<div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        SyncGrades Settings
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="row">
                    <div
                        v-if="$_userMixins_isSchoolAdmin"
                        class="col-xl-6"
                    >
                        <NavigationMenu
                            title="School Administration"
                            icon="school"
                            :items="adminLinks"
                        />
                    </div>
                    <div
                        v-if="$_userMixins_isSchoolAdmin"
                        class="col-xl-6"
                    >
                        <NavigationMenu
                            title="File Management"
                            icon="download"
                            :items="fileLinks"
                        />
                    </div>
                    <div
                        v-if="$_userMixins_isSchoolAdmin"
                        class="col-xl-6"
                    >
                        <NavigationMenu
                            title="Report Cards"
                            icon="reportCards"
                            :items="reportCardLinks"
                        />
                    </div>
                    <div class="col-xl-6">
                        <NavigationMenu
                            title="Google Classroom"
                            icon="googleClassroom"
                            :items="googleLinks"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import NavigationMenu from '../components/NavigationMenu.vue';
import userMixins from '../store/mixins/userMixins';

export default Vue.extend({
    name: 'SettingsMenu',
    components: { NavigationMenu },
    mixins: [userMixins],
    computed: {
        googleLinks() {
            const links = [{
                icon: null,
                routeName: 'GoogleAccount',
                title: 'Account',
            }, {
                icon: null,
                routeName: 'GoogleCourseManager',
                title: 'Course Manager',
            }];

            if (this.$_userMixins_isSchoolAdmin) {
                links.push({
                    icon: null,
                    routeName: 'GoogleUserLink',
                    title: 'User Linker',
                });
            }
            return links;
        },
        fileLinks() {
            const links = [{
                icon: null,
                routeName: 'UploadRosters',
                title: 'Update Rosters',
            }, {
                icon: null,
                routeName: 'UploadAttendance',
                title: 'Attendance Uploader',
            }, {
                icon: null,
                routeName: 'UploadStudentImages',
                title: 'Student Images',
            }, {
                icon: null,
                routeName: 'UploadStudentPdf',
                title: 'Official PDFs',
            }, {
                icon: null,
                routeName: 'ExportFiles',
                title: 'Data Exports',
            }];

            return links;
        },
        reportCardLinks() {
            const links = [{
                icon: null,
                routeName: 'UploadReportCards',
                title: 'Upload Report Cards',
            }, {
                icon: null,
                routeName: 'DownloadReportCards',
                title: 'Download Report Cards',
            }, {
                icon: null,
                routeName: 'ReportCardMarkingPeriods',
                title: 'Setup Marking Period',
            }, {
                icon: null,
                routeName: 'ValidMarks',
                title: 'Define Valid Marks',
            }];

            return links;
        },
        adminLinks() {
            const links = [{
                icon: null,
                routeName: 'ManageUsers',
                title: 'Manage Users',
            }, {
                icon: null,
                routeName: 'ConfigureTerm',
                title: 'Configure Term',
            }, {
                icon: null,
                routeName: 'AnecdotalSetup',
                title: 'Anecdotal Setup',
            }, {
                icon: null,
                routeName: 'MarkingPeriods',
                title: 'Marking Periods',
            }, {
                icon: null,
                routeName: 'GradeTemplateList',
                title: 'Grade Templates',
            }];

            return links;
        },

    },
});

</script>
