var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-section pt-4" }, [
    _c(
      "div",
      {
        staticClass:
          "kt-section__content kt-section__content--border kt-section__content--fit",
      },
      [
        _c(
          "ul",
          { staticClass: "kt-nav kt-nav--md-space kt-nav--lg-font" },
          [
            _c(
              "li",
              { staticClass: "kt-nav__head mb-3" },
              [
                _vm._v(" " + _vm._s(_vm.title) + " "),
                _c("SVGIcon", {
                  staticClass: "kt-nav__link-icon kt-svg-icon",
                  attrs: { name: _vm.icon },
                }),
              ],
              1
            ),
            _vm._l(_vm.items, function (item, idx) {
              return _c(
                "li",
                {
                  key: `route_${idx}_${item.routeName}`,
                  staticClass: "kt-nav__item",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-nav__link",
                      attrs: { to: { name: item.routeName } },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "kt-nav__link-bullet kt-nav__link-bullet--dot",
                        },
                        [_c("span")]
                      ),
                      _c("span", { staticClass: "kt-nav__link-text" }, [
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ]),
                      _c("span", { staticClass: "kt-nav__link-badge d-none" }, [
                        _c(
                          "span",
                          { staticClass: "kt-badge kt-badge--warning" },
                          [_vm._v("3")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }